package com.kelimesoft.cowmaster.models

import kotlinx.serialization.Serializable


@Serializable
data class SyncUser(
    var email: String? = null,
    var hr: Boolean = true,
    var hw: Boolean = true,
    var hmw: Boolean = true,
    var mr: Boolean = true,
    var mw: Boolean = true,
    var fr: Boolean = true,
    var fw: Boolean = true,
    var ras: Boolean = true
)

@Serializable
data class SyncUserData(
    var admin: String? = null,
    var users: List<SyncUser>? = null
)

@Serializable
data class SyncUserResponse(
    val data: SyncUserData?,
    val error: String? = null
)

@Serializable
data class AddSyncUserResponse(
    var pass: String? = null
)