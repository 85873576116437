package com.kelimesoft.cowmaster.pages.profile

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.MilkTypeScreen
import com.kelimesoft.cowmaster.styles.MainButtonStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.ProfileVM
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.icons.fa.FaDeleteLeft
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.LineStyle

import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

@Composable

fun EditSharedUser(profileVM: ProfileVM, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    var errorText by remember { mutableStateOf("") }

    var canSeeCattle by remember { mutableStateOf(true) }
    var canEditCattle by remember { mutableStateOf(true) }
    var canBireyMilk by remember { mutableStateOf(true) }
    var canRasyon by remember { mutableStateOf(true) }
    var canSeeMilk by remember { mutableStateOf(true) }
    var canEditMilk by remember { mutableStateOf(true) }
    var canSeeFin by remember { mutableStateOf(true) }
    var canEditFin by remember { mutableStateOf(true) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(460.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .color(AppColors.TextColor),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        DialogTitle("Kullanıcı Yetkileri".tr(), onClose = onClose)

        SpanText(
            profileVM.editUser!!.email!!,
            modifier = Modifier
                .fontSize(18.px)
                .padding(4.px)
                .textAlign(TextAlign.Center)
                .backgroundColor(AppColors.GrayDark)
                .borderRadius(r = 6.px)
                .color(Colors.White)
                .textAlign(TextAlign.Center)
        )

        Column(
            Modifier
                .fillMaxWidth()
                .padding(leftRight = 10.px, bottom = 6.px)
                .borderRadius(6.px)
                .border(1.px, color = AppColors.Gray.copyf(alpha = 0.2f), style = LineStyle.Solid)
                .margin(top = 2.px)
        ) {


            SpanText(
                "Hayvan Yönetimi",
                modifier = Modifier
                    .fontSize(14.px)
                    .padding(4.px)
                    .textAlign(TextAlign.Center)
            )
            VDivider()

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "see-cattle") {
                    SpanText("Görebilir".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("see-cattle"),
                    checked = canSeeCattle,
                    onCheckedChange = {
                        canSeeCattle = it
                    }
                )

            }

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "edit-cattle") {
                    SpanText("Ekle / Düzenle".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("edit-cattle"),
                    checked = canEditCattle,
                    onCheckedChange = {
                        canEditCattle = it
                    }
                )

            }

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "ekle-birey-milk") {
                    SpanText("Birey Süt Ekle".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("ekle-birey-milk"),
                    checked = canBireyMilk,
                    onCheckedChange = {
                        canBireyMilk = it
                    }
                )

            }




        }

        Column(
            Modifier
                .fillMaxWidth()
                .padding(leftRight = 10.px, bottom = 6.px)
                .borderRadius(6.px)
                .border(1.px, color = AppColors.Gray.copyf(alpha = 0.2f), style = LineStyle.Solid)
                .margin(top = 2.px)
        ) {

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "rasyon-cattle") {
                    SpanText("Rasyon/Stok Yönetimi".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("rasyon-cattle"),
                    checked = canSeeCattle,
                    onCheckedChange = {
                        canSeeCattle = it
                    }
                )

            }

        }

        Column(
            Modifier
                .fillMaxWidth()
                .padding(leftRight = 10.px, bottom = 6.px)
                .borderRadius(6.px)
                .border(1.px, color = AppColors.Gray.copyf(alpha = 0.2f), style = LineStyle.Solid)
                .margin(top = 2.px)
        ) {


            SpanText(
                "Süt Yönetimi",
                modifier = Modifier
                    .fontSize(14.px)
                    .padding(4.px)
                    .textAlign(TextAlign.Center)
            )
            VDivider()

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "see-milk") {
                    SpanText("Görebilir".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("see-milk"),
                    checked = canSeeMilk,
                    onCheckedChange = {
                        canSeeMilk = it
                    }
                )

            }

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "edit-milk") {
                    SpanText("Ekle / Düzenle".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("edit-milk"),
                    checked = canEditMilk,
                    onCheckedChange = {
                        canEditMilk = it
                    }
                )

            }


        }


        Column(
            Modifier
                .fillMaxWidth()
                .padding(leftRight = 10.px, bottom = 6.px)
                .borderRadius(6.px)
                .border(1.px, color = AppColors.Gray.copyf(alpha = 0.2f), style = LineStyle.Solid)
                .margin(top = 2.px)
        ) {


            SpanText(
                "Muhasebe Yönetimi",
                modifier = Modifier
                    .fontSize(14.px)
                    .padding(4.px)
                    .textAlign(TextAlign.Center)
            )
            VDivider()

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "see-fin") {
                    SpanText("Görebilir".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("see-fin"),
                    checked = canSeeFin,
                    onCheckedChange = {
                        canSeeFin = it
                    }
                )

            }

            Row(modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px, topBottom = 2.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Label(forId = "edit-fin") {
                    SpanText("Ekle / Düzenle".tr(), modifier = Modifier.margin(left = 4.px))
                }

                Switch(
                    modifier = Modifier.id("edit-fin"),
                    checked = canEditFin,
                    onCheckedChange = {
                        canEditFin = it
                    }
                )

            }


        }




            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceAround
            ) {
                Button(
                    attrs = MainButtonStyle.toModifier()
                        .backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    FaTrash(modifier = Modifier.padding(2.px))
                    Text("Kullanıcıyı Sil".tr())
                }

                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()

                        }
                        .toAttrs()

                ) {
                    FaSave(modifier = Modifier.padding(2.px))
                    Text("Yetki Kaydet".tr())
                }
            }

        }


}