package com.kelimesoft.cowmaster.pages.profile

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.SummaryCard
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Icons
import com.kelimesoft.cowmaster.models.Values
import com.kelimesoft.cowmaster.utils.addDays
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronRight
import com.varabyte.kobweb.silk.components.icons.fa.FaUserGroup
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Composable
fun PaketBilgi() {
    SummaryCard(title = "Paket Bilgileri") {
        Column(modifier = Modifier.fillMaxWidth()) {
            val paket = AppData.cowUser?.paket ?: 0
            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(4.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier
                        .padding(4.px)
                        .size(36.px)
                        .backgroundColor(AppColors.PrimaryDark)
                        .borderRadius(50.percent),
                    contentAlignment = Alignment.Center

                ) {
                    FaUserGroup(
                        size = IconSize.LG,
                        modifier = Modifier.padding(4.px)
                            //.backgroundColor(AppColors.Primary)
                            .color(Color.white)
                        //.borderRadius(r = 50.percent)
                    )
                }


                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(4.px)

                ) {

                    SpanText(
                        "paket${paket}_title".tr(),
                        modifier = Modifier.fontSize(FontSize.Large)
                            .color(AppColors.Primary)
                            .padding(2.px)
                    )
                    SpanText(
                        "paket${paket}_user_sayi".tr(),
                        modifier = Modifier.fontSize(FontSize.Medium)
                            .color(AppColors.TextColor)
                            .padding(2.px)
                    )
                }
            }

            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(6.px)
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth()
                        .height(54.px)
                        .backgroundColor(AppColors.Red)
                        .borderRadius(4.px)
                        .padding(3.px)
                        .margin(3.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Image(
                        modifier = Modifier
                            .borderRadius(r = 50.percent)
                            .size(40.px)
                            .objectFit(ObjectFit.Fill)
                            .padding(6.px)
                            .backgroundColor(Color.white),
                        src = Values.imgPath1 + Icons.cows,

                        )

                    SpanText(
                        "paket${paket}_hayvan_sayi".tr(),
                        modifier = Modifier.fontSize(FontSize.Large)
                            .color(Color.white)
                            .padding(2.px)
                    )
                }

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .height(54.px)
                        .backgroundColor(AppColors.GrayDark)
                        .borderRadius(4.px)
                        .padding(3.px)
                        .margin(3.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    SpanText(
                        "Paket Detayları",
                        modifier = Modifier.fontSize(FontSize.Large)
                            .color(Color.white)
                            .padding(2.px)
                    )
                    Spacer()
                    FaChevronRight(
                        size = IconSize.LG,
                        modifier = Modifier.padding(4.px)
                            //.backgroundColor(AppColors.Primary)
                            .color(Color.white)
                        //.borderRadius(r = 50.percent)
                    )
                }
            }


            Span(
                attrs = Modifier
                    .padding(6.px)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text("Premium Süre Bitiş Tarihi: ")
                AppData.cowUser?.let { cuser ->
                    Span(
                        attrs = Modifier
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) {
                        Text(Date(cuser.startDate).addDays(365).toLocaleDateString())
                    }
                }

            }


        }
    }
}