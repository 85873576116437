package com.kelimesoft.cowmaster.pages.profile

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.SummaryCard
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Images
import com.kelimesoft.cowmaster.models.Routes
import com.kelimesoft.cowmaster.models.Values

import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text

@Composable
fun HesapBilgi() {
    val scope = rememberCoroutineScope()
    val cowUser = AppData.cowUser
    var profileLogo by remember { mutableStateOf("") }
    LaunchedEffect(Unit, AppData.cowUser) {
        AppData.cowUser?.logo?.let { path ->
            profileLogo = "${Routes.LogoRoot}${path}"
        }
        //console.log("logo:", profileLogo)

    }
    SummaryCard(title = "Hesap Bilgileri") {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(leftRight = 6.px),
            verticalArrangement = Arrangement.Top
        ) {
            Row(modifier = Modifier.fillMaxWidth()) {
                Image(
                    modifier = Modifier
                        .borderRadius(r = 30.percent)
                        .maxWidth(148.px)
                        .attrsModifier {
                            //on error özelliği ile attr ekledim, resim var ama alınamadı/ başka folder da
                            attr(
                                "onerror",
                                "this.onerror=null;this.src=\"${Routes.WebRoot}${Images.noImage}\";"
                            )
                        }
                        .objectFit(ObjectFit.Fill)
                        .padding(6.px),
                    src = profileLogo.ifEmpty { Values.imgPath1 + Images.noImage },

                    )

                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(6.px),
                    horizontalAlignment = Alignment.Start
                ) {
                    HesapBilgiRow("Kullanıcı Adı", cowUser?.email ?: "")
                    VDivider()
                    HesapBilgiRow("İşletme Adı", cowUser?.name ?: "")
                    VDivider()
                    HesapBilgiRow("İşletme Numarası", cowUser?.dairyId ?: "")
                    VDivider()

                }


            }

            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(6.px),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                /*
                Button(attrs = TextButtonStyle
                    .toModifier()
                    .color(AppColors.NumOrange)
                    .fontWeight(FontWeight.SemiBold)
                    .fontSize(14.px)

                    .onClick {

                    }
                    .toAttrs()) {
                    Text("Şifre Değiştir")
                }*/

                Button(attrs = TextButtonStyle.toModifier()
                    .color(Colors.Red)
                    .fontWeight(FontWeight.SemiBold)
                    .fontSize(14.px)
                    .onClick {
                        scope.launch {
                            AppData.appViewModel.userLogout()
                        }
                    }
                    .toAttrs()) {
                    Text("Çıkış Yap")
                }
            }

        }

    }
}



@Composable
private fun HesapBilgiRow(
    title: String,
    text: String
) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .padding(2.px),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        SpanText(title,
            modifier = Modifier
                .textAlign(TextAlign.Start)
                .color(AppColors.Gray)
                .fontSize(FontSize.Small)
        )

        SpanText(
            text,
            modifier =  Modifier
                .fillMaxWidth(60.percent)
                .textAlign(TextAlign.Start)
                .color(AppColors.TextColor)
                .fontSize(FontSize.Medium)
        )
    }

}