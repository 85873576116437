package com.kelimesoft.cowmaster.pages.profile

import androidx.compose.runtime.Composable
import com.kelimesoft.cowmaster.components.SummaryCard
import com.kelimesoft.cowmaster.components.VDivider
import com.kelimesoft.cowmaster.components.sleep
import com.kelimesoft.cowmaster.models.AppColors
import com.kelimesoft.cowmaster.models.Icons
import com.kelimesoft.cowmaster.models.Images
import com.kelimesoft.cowmaster.models.SyncUser
import com.kelimesoft.cowmaster.styles.SharedUserStyle
import com.kelimesoft.cowmaster.viewmodals.ProfileVM
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun UserListView(admin: String, ulist: List<SyncUser>, onEdit: (SyncUser) -> Unit) {

    SummaryCard(title = "Kullanıcılar") {
        Column (modifier = Modifier
            .color(AppColors.TextColor)
            .fillMaxWidth()
            .padding(2.px)
        ) {
            Column(modifier = Modifier
                .fillMaxWidth()
                .padding(2.px)
                .borderRadius(8.px)
                .border(1.px, color = AppColors.Gray.copyf(alpha = 0.2f), style = LineStyle.Solid)
                .margin(bottom = 2.px)

            ) {
                SpanText(
                    "Yönetici Hesap",
                    modifier = Modifier
                        .fontSize(15.px)
                        .fillMaxWidth()
                        .textAlign(TextAlign.Center)
                )

                Row(
                    modifier = SharedUserStyle.toModifier()
                        .color(AppColors.PrimaryDark)
                        .fillMaxWidth(),

                    verticalAlignment = Alignment.CenterVertically
                ) {

                    FaUserGear(size = IconSize.SM)
                    SpanText(
                        admin,
                        modifier = Modifier
                            .fontSize(16.px)
                            .padding(4.px)
                    )
                }
            }




            Column(modifier = Modifier
                .fillMaxWidth()
                .color(AppColors.TextColor)
                .padding(2.px)
                .borderRadius(8.px)
                .border(1.px, color = AppColors.Gray.copyf(alpha = 0.2f), style = LineStyle.Solid)
            ) {
                SpanText("Kullanıcı Hesapları",
                    modifier = Modifier
                        .fontSize(15.px)
                        .fillMaxWidth()
                        .padding(4.px)
                        .textAlign(TextAlign.Center)
                )
                VDivider()
                ulist.forEach { synuser ->
                    SyncUserRow(synuser, true){
                        onEdit(synuser)
                    }
                }
            }
        }



    }
}



@Composable
fun SyncUserRow(
    user: SyncUser,
    showYetki: Boolean,
    onSelect: () -> Unit
) {

    Row(
        modifier = SharedUserStyle.toModifier()
            .fillMaxWidth()
            .onClick {
                onSelect()
            }
        ,

        verticalAlignment = Alignment.CenterVertically
    ) {
        FaUser(
            style = IconStyle.FILLED,
            size = IconSize.SM, modifier = Modifier.color(AppColors.Gray))
        SpanText(user.email ?: "",
            modifier = Modifier
                .fontSize(16.px)
                .padding(4.px)
        )

        Spacer()

        if (showYetki) {
            if (user.hr) {
                Image(
                    src = if (user.hw) Icons.inekPrimary else Icons.inek,
                    modifier = Modifier
                        .size(24.px)
                        .padding(1.px)
                        .margin(1.px)
                )
            }
            if (user.ras) {
                Image(
                    src = Icons.rasyonPrimary,
                    modifier = Modifier
                        .size(22.px)
                        .padding(1.px)
                        .margin(1.px)
                )
            }
            if (user.mr) {
                Image(
                    src = if (user.mw) Icons.milkPrimary else Icons.biberon,
                    modifier = Modifier
                        .size(22.px)
                        .padding(1.px)
                        .margin(1.px)
                )
            }
            if (user.fr) {
                Image(
                    src = if (user.fw) Icons.financePrimary else Icons.financeDark,
                    modifier = Modifier
                        .size(25.px)
                        .padding(1.px)
                        .margin(1.px)
                )
            }
        }
    }

}
