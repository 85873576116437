package com.kelimesoft.cowmaster.network

import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.cowdetails.DisSebep
import com.kelimesoft.cowmaster.viewmodals.AppData
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.xhr.FormData
import kotlin.js.Promise
import kotlin.js.json


// Used throughout to wrap api calls and effects.
val mainScope = MainScope()

private suspend fun Promise<Response>.assertStatus() = await().apply {
    status.toInt().also {
        check(200 == it || 0 == it) {
            "Operation failed: $status  $url".also { msg ->
                window.alert(msg)
            }
        }
    }
}

/*
suspend fun fetch(method: String = "GET", url: String, body: dynamic = null): ApiResponse {
    val res = window.fetch(url, object : RequestInit {
        override var method: String? = method
        override var body: dynamic = body
        override var headers: dynamic = json("Accept" to "application/json")
    }).await()
    return if (res.ok) res.json().await() as ApiResponse else ApiResponse(data = null, error = res.statusText)
}

 */


/*
suspend fun <T> requestWorks(url: String, method: String = "GET", form: FormData? = null): T {
    //val sessionId = form?.get("session-id")
    //console.log("sesid $sessionId")

    val res = window.fetch(url, object : RequestInit {
        override var method: String? = method
        override var body: FormData? = form
        override var headers: dynamic = json("Accept" to "application/json")
    }).await()
    return if (res.ok) res.json().await() as T else throw makeError(res)
}
*/


/*
private suspend fun fetch(method: String, url: String, body: String? = null): Response =
    window.fetch(
        url, RequestInit(
            method = method,
            body = body,
            headers = json(
                //"Access-Control-Allow-Origin" to "*",
                //"Content-Type" to "application/x-www-form-urlencoded",
                "Content-Type" to "application/json, charset=UTF-8",
                //"Content-Type" to "multipart/form-data",
                "Accept" to "application/json",

                //"Access-Control-Allow-Methods" to "POST",
                //"Access-Control-Allow-Headers" to "Content"
            )
        )
    ).await()
*/
// Verbiage: expressing the semantics of each method.

/*
private suspend fun get(url: String): Response =
    fetch("GET", url)
*/

/*
private suspend fun put(url: String, body: dynamic): Response =
    fetch("PUT", url, JSON.stringify(body))*/

/*
private suspend fun post(url: String, form: FormData): ApiResponse? {
    //console.log(JSON.stringify(json))
    console.log(form.toString())
    return  request(url = url, method = "POST", body = form)
}*/


/*
private suspend fun delete(url: String): Response =
    fetch("DELETE", url)*/

/**
 * Serialize object from json in response.
 */
private suspend inline fun <reified T> json(response: Response): T =
    Json.decodeFromString(response.text().await())

/**
 * The API methods, qmirroring the server.
 */
object RestApi {

    const val connectErr = "{\"data\":null, error=\"Connection Error\"}"

    val jsonX = Json { encodeDefaults = true }

    suspend fun getUserProfile(): String? {
       return postJson("${Routes.ApiRoot}getprofile", jsonX.encodeToString(BasicRequest()))
    }

    suspend fun userLogin(formData: FormData): String? {
        return postForm("${Routes.ApiRoot}login", formData)
    }


    suspend fun forgotPass(user: String, lang: String): String? {
        //console.log("forgot-pass:", user, lang)
        val request = ForgotPassRequest(user, lang)
        return postJson("${Routes.ApiRoot}forgotpass", jsonX.encodeToString(request))
    }

    suspend fun resetUserPass(user: String, reset: String, new: String): String? {
        //console.log("forgot-pass:", user, lang)
        val request = ResetPassRequest(user, reset, new)
        return postJson("${Routes.ApiRoot}resetpass", jsonX.encodeToString(request))
    }


    suspend fun getCaptcha(): String? {
        return apiGet("${Routes.ApiRoot}getcaptcha")
    }

    suspend fun getSummary(sum: SumItems): String? {
        return postJson("${Routes.ApiRoot}summary", jsonX.encodeToString(SummaryRequest(sum = sum)))
    }

    //HerdList

    suspend fun getHerdList(): String? {
        return postJson("${Routes.ApiRoot}herdlist", jsonX.encodeToString(BasicRequest()))
    }

    suspend fun getDeletedList(): String? {
        return postJson("${Routes.AppRoot}dellist", jsonX.encodeToString(BasicRequest()))
    }

    suspend fun undoDeletedCow(hid: Long): String? {
        val request = UndoCowDelRequest(hid = hid)
        return postJson("${Routes.AppRoot}undodelcow", jsonX.encodeToString(request))
    }

    suspend fun delHerd(herdid: Long): String? {
        val request = DeleteHerdRequest(herd = herdid)
        return postJson("${Routes.ApiRoot}delherd", jsonX.encodeToString(request))
    }

    suspend fun saveHerd(herd: HerdItem): String? {
        val request = SaveHerdRequest(herd = herd)
        return postJson("${Routes.ApiRoot}saveherd", jsonX.encodeToString(request))
    }

    //CowList

    suspend fun getCowList(list: String, page: Int = 1, order: String = ""): String? {
        val request = CowListRequest(list = list, page = page, order = order)
        return postJson("${Routes.ApiRoot}cowlist", jsonX.encodeToString(request))
    }

    suspend fun getSingleCow(hid: Long): String? {
        val request =  CowInfoRequest(hid = hid)
        return postJson("${Routes.ApiRoot}listcow", jsonX.encodeToString(request))
    }

    suspend fun addNewCow(cow: NewListCow): String? {
        val newCowBind =  NewCowAddBinding(cow = cow)
        return postJson("${Routes.ApiRoot}addcow", jsonX.encodeToString(newCowBind))
    }

    suspend fun saveCowList(cowlist: List<NewListCow>, weaning: Int = 0): String? {
        val cowListBinding =  NewCowListBinding(cowlist = cowlist, weaning = weaning)
        return postJson("${Routes.ApiRoot}addcows", jsonX.encodeToString(cowListBinding))
    }

    //CowDetails
    suspend fun getCowDetails(hid: Long): String? {
        val request =  CowInfoRequest(hid = hid)
        return postJson("${Routes.ApiRoot}cowdetails", jsonX.encodeToString(request))
    }

    suspend fun deleteCowImage(hid: Long, image: String, fav: Int): String? {
        val request =  DelCowImgRequest(hid = hid, image = image, fav = fav)
        return postJson("${Routes.ApiRoot}delcowimg", jsonX.encodeToString(request))
    }
    suspend fun addCowImage(hid: Long, image: String): String? {
        val request =  AddCowImgRequest(hid = hid, image = image)
        return postJson("${Routes.ApiRoot}addcowimg", jsonX.encodeToString(request))
    }

    suspend fun setCowFavImage(hid: Long, image: String): String? {
        val request =  AddCowImgRequest(hid = hid, image = image)
        return postJson("${Routes.ApiRoot}setfavimg", jsonX.encodeToString(request))
    }

    suspend fun getCowCalves(kupe: String): String? {
        val request =  CowCalvesRequest(kupe = kupe)
        return postJson("${Routes.ApiRoot}cowcalves", jsonX.encodeToString(request))
    }
    suspend fun getSagmalDonem(hid: Long): String? {
        val request =  CowInfoRequest(hid = hid)
        return postJson("${Routes.ApiRoot}cowsagmaldnm", jsonX.encodeToString(request))
    }

    suspend fun updateCowHerd(hid: Long, herd: Long): String? {
        val request = UpdateCowHerdRequest(hid =hid, herd = herd)
        return postJson("${Routes.ApiRoot}updcowherd", jsonX.encodeToString(request))
    }

    suspend fun addEvent(event: NewEvent, isLast: Boolean): String? {
        val eventBinding =  NewEventBinding(event = event, latest = isLast)
        return postJson("${Routes.ApiRoot}addevent", jsonX.encodeToString(eventBinding))
    }

    suspend fun deleteCowEvent(hid: Long, eid: Long, cat: String, isLast: Boolean): String? {
        val delRquest =  DelCowEventtRequest(hid = hid, eid = eid, cat = cat, latest = isLast)
        return postJson("${Routes.ApiRoot}delcowevt", jsonX.encodeToString(delRquest))
    }

    suspend fun addNotif(notif: PersonNotif): String? {
        val notifBinding =  NewNotifBinding(notif = notif)
        return postJson("${Routes.ApiRoot}addnotif", jsonX.encodeToString(notifBinding))
    }

    suspend fun deleteNotif(nid: Long): String? {
        val notifBinding =  DelNotifRequest(nid= nid)
        return postJson("${Routes.ApiRoot}notifdelete", jsonX.encodeToString(notifBinding))
    }
    suspend fun toggleNotif(nid: Long, done: Int): String? {
        val notifBinding =  ToggleNotifRequest(nid= nid, done = done)
        return postJson("${Routes.ApiRoot}notiftoggle", jsonX.encodeToString(notifBinding))
    }

    suspend fun addCowMilk(milk: NewCowMilk): String? {
        val milkBinding =  NewMilkBinding(milk = milk)
        return postJson("${Routes.ApiRoot}addcowmilk", jsonX.encodeToString(milkBinding))
    }

    suspend fun editSingleCow(editedCow: EditedCow): String? {
        val cowBinding =  EditedCowAddBinding(cow = editedCow)
        return postJson("${Routes.ApiRoot}editcow", jsonX.encodeToString(cowBinding))
    }
    suspend fun disposeCow(hid: Long, sebep: DisSebep, notes: String, date: String): String? {
        val request = DisposeCowRequest(hid = hid, sebep = sebep, notes = notes, date = date)
        return postJson("${Routes.ApiRoot}addisposed", jsonX.encodeToString(request))
    }

    suspend fun deleteCow(hid: Long, delMilk: Boolean): String? {
        val request = DeleteCowRequest(hid = hid, milk = if (delMilk) 1 else 0)
        return postJson("${Routes.ApiRoot}addelete", jsonX.encodeToString(request))
    }



    //NOTİF list api

    suspend fun getNotifList(start: String, end: String): String? {
        val request = NotifListRequest(start = start, end = end)
        return postJson("${Routes.ApiRoot}notiflist", jsonX.encodeToString(request))
    }

    suspend fun updateNotif(nid: Long, tmm: Int): String? {
        val formData = FormData()
        formData.append("nid", nid.toString())
        formData.append("tmm", tmm.toString())
        return postForm("${Routes.ApiRoot}updnotif", formData)
    }

    //Finance NEtwork

    suspend fun getFinList(type: FinType?, start: String, end: String): String? {
        val finBinding =  FinListRequest(start = start, end = end)
        return postJson("${Routes.ApiRoot}finlist", jsonX.encodeToString(finBinding))
    }

    suspend fun addFinItems(type: FinType, list: List<FinItem>): String? {
        val finBinding =  AddFinItemsRequest(type = type, list = list)
        return postJson("${Routes.ApiRoot}addfinitems", jsonX.encodeToString(finBinding))
    }

    suspend fun updateFinItem(finItem: FinItem): String? {
        val finBinding =  FinItemBinding(fin = finItem)
        return postJson("${Routes.ApiRoot}editfin", jsonX.encodeToString(finBinding))
    }

    suspend fun delFinItem(fin: FinItem): String? {
        val finBinding =  FinItemBinding(fin = fin)
        return postJson("${Routes.ApiRoot}delfin", jsonX.encodeToString(finBinding))
    }


    //Dismiss Cattle Services
    suspend fun getDisposedList(year: Int): String? {
        val request =  DisposedListRequest(year = year.toString())
        return postJson("${Routes.ApiRoot}dislist", jsonX.encodeToString(request))
    }

    suspend fun delDismissedCow(id: Long, delMilk: Boolean): String? {
        val request = DeleteCowRequest(hid = id, milk = if(delMilk) 1 else 0)
        return postJson("${Routes.AppRoot}disdelete", jsonX.encodeToString(request))
    }
    suspend fun dismissCowBack(id: Long): String? {
        val request = DeleteCowRequest(hid = id)
        return postJson("${Routes.AppRoot}disback", jsonX.encodeToString(request))
    }

    //Milk services
    suspend fun getDairyMilk(start: String, end: String): String? {
        val request = MilkDataRequest(start = start, end = end)
        return postJson("${Routes.AppRoot}totalmilk", jsonX.encodeToString(request))
    }

    suspend fun addTotalMilk(milk: TotalMilk): String? {
        val milkBinding =  TotalMilkBinding(milk = milk)
        return postJson("${Routes.AppRoot}addtotalmilk", jsonX.encodeToString(milkBinding))
    }

    suspend fun addManyCowMilk(milk: ManyCowMilk): String? {
        val milkBinding =  ManyMilkRequest(milk = milk)
        return postJson("${Routes.AppRoot}addmanymilk", jsonX.encodeToString(milkBinding))
    }

    //Milk services
    suspend fun getCowMilkData(start: String, end: String): String? {
        val request = MilkDataRequest(start = start, end = end)
        return postJson("${Routes.AppRoot}bireymilk", jsonX.encodeToString(request))
    }

    suspend fun getSagmalList(): String? {
        return postJson("${Routes.AppRoot}sagmallist", jsonX.encodeToString(BasicEmailRequest()))
    }

    suspend fun deleteDairyMilk(mid: Long): String? {
        val request = DeleteMilkRequest(mid = mid)
        return postJson("${Routes.AppRoot}deltotalmilk", jsonX.encodeToString(request))
    }

    suspend fun deleteCowMilk(mid: Long): String? {
        val request = DeleteMilkRequest(mid = mid)
        return postJson("${Routes.AppRoot}delcowmilk", jsonX.encodeToString(request))
    }


    //Event List services

    suspend fun getEventList(start: String, end: String): String? {
        val request =  EventListRequest(start = start, end = end)
        return postJson("${Routes.ApiRoot}eventlist", jsonX.encodeToString(request))
    }

    suspend fun getEventCowList(): String? {
        return postJson("${Routes.ApiRoot}eventcows", jsonX.encodeToString(BasicRequest()))
    }


    suspend fun saveBulkEvents(bulkEvent: NewBulkEvent): String? {

        return postJson("${Routes.ApiRoot}savebulk", jsonX.encodeToString(bulkEvent))
    }


    suspend fun deleteListEvent(event: ListEvent): String? {
        val request =  DeleteEventRequest(hid = event.hid, eid = event.id, cat = event.cat)
        return postJson("${Routes.ApiRoot}delevent", jsonX.encodeToString(request))
    }

    suspend fun getUserList(): String? {
        return postJson("${Routes.ApiRoot}synclist", jsonX.encodeToString(BasicRequest()))
    }

    suspend fun logOut(): String? {
        return postForm("${Routes.ApiRoot}logout")
    }

    private fun mapToForm(map: Map<String, String>): FormData {
        var form = FormData()
        for (key in map.keys){
            form.append(key, map[key]!!)
        }
        return form
    }

    private suspend fun postForm(url: String, body: FormData? = null): String? {
        var form = body
        if (form != null){
            val sessionId: String = form.get("session-id") as? String ?: ""
            if (sessionId.isEmpty()){
                form.set("session-id", AppData.sessionId)
            }
        }else{
            form = FormData()
            form.set("session-id", AppData.sessionId)
        }
        //console.log("sesid $sessionId")
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "POST"
            override var body: FormData? = form
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }

    private suspend fun postJson(url: String, json: String? = null): String? {
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "POST"
            override var body: String? = json
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }

    private suspend fun apiGet(url: String): String? {
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "GET"
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }
}












