package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.finance.*
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.pages.Router
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px

@Page("/gelir-gider")
@Composable
fun DairyFinance() {
    AppData.appViewModel.activeScreens = Screens.Finance
    Router()
}

@Composable
fun GelirGider() {
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()

    val cowUser: CowUser? = AppData.cowUser

    val finVM = AppData.financeVM

    LaunchedEffect(true){
        if (cowUser?.canSeeFin == true){
            finVM.getFinanceList()
        }

    }

    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
    ) {
        Column(
            modifier = MainPageStyle.toModifier(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Row(
                modifier = Modifier.fillMaxSize(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint)
                }

                if (cowUser?.canSeeFin == true ){
                    Column(modifier = Modifier.fillMaxSize()) {
                        FinBar(financeVM = finVM, scope)
                        Row(modifier = Modifier.fillMaxSize()) {

                            GelirlerTablo(modifier = Modifier.fillMaxSize().weight(1f), finVM)

                            Box(modifier = Modifier
                                .fillMaxHeight()
                                .width(2.px)
                                .backgroundColor(Colors.Gray.opacity(0.5f))) {

                            }
                            GiderlerTablo(modifier = Modifier.fillMaxSize().weight(1f), finVM)
                        }
                    }
                }else{
                    NoContentYet("fin_yetki".tr())
                }



            }
            if (breakpoint < Breakpoint.SM) {
                Spacer()
                BottomNavigation(breakpoint)
            }

        }

    }
}