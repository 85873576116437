package com.kelimesoft.cowmaster.viewmodals

import com.kelimesoft.cowmaster.models.Screens

open class BaseVM {
    suspend fun checkUser(): Boolean{
        if (AppData.sessionId.isEmpty()) {
            AppData.appViewModel.activeScreens = Screens.Login
            return false
        }else if (AppData.cowUser == null){
            val result = AppData.appViewModel.getUserInfo()
            if (!result){
                AppData.appViewModel.activeScreens = Screens.Login
                return false
            }
        }
        if(AppData.cowUser?.paket in 6..8){
            return true
        }
        AppData.appViewModel.activeScreens = Screens.Yukselt
        return false
    }
}