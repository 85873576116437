package com.kelimesoft.cowmaster.pages

import StaggeredVerticalGrid2
import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.eventlist.AddBulkEvent
import com.kelimesoft.cowmaster.pages.profile.EditSharedUser
import com.kelimesoft.cowmaster.pages.profile.HesapBilgi
import com.kelimesoft.cowmaster.pages.profile.PaketBilgi
import com.kelimesoft.cowmaster.pages.profile.UserListView
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.styles.TextButtonStyle
import com.kelimesoft.cowmaster.utils.addDays
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.ProfileVM
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronRight
import com.varabyte.kobweb.silk.components.icons.fa.FaUserGroup
import com.varabyte.kobweb.silk.components.icons.fa.FaUsers
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date

@Page("/profil")
@Composable
fun ProfilePage() {
    AppData.appViewModel.activeScreens = Screens.Profile
    Router()
}

@Composable
fun MyProfile() {
    val breakpoint = rememberBreakpoint()
    val profileVM = ProfileVM()

    var adminUser by remember { mutableStateOf("")}
    var userList: List<SyncUser> by remember { mutableStateOf(listOf())}

    var showEditUser by remember { mutableStateOf(false) }

    LaunchedEffect(Unit){

        profileVM.getUserList()?.let { sres ->
            adminUser = sres.data?.admin ?: ""
            userList = sres.data?.users ?: listOf()
        }
    }

    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
    ) {
        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            Row(
                modifier = Modifier.fillMaxSize()
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint)
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .thenIf(breakpoint <= Breakpoint.SM) {
                            Modifier.overflow { y(Overflow.Auto) }
                                .overflow { x(Overflow.Hidden) }
                                .fillMaxHeight()
                                .scrollBehavior(ScrollBehavior.Smooth)
                                .styleModifier {
                                    property("scrollbar-width", "thin")
                                }
                        }
                        .thenIf(breakpoint > Breakpoint.SM) {
                            Modifier.overflow(Overflow.Hidden)
                        }
                        .padding(leftRight = 4.px)
                ) {
                    Column(
                        modifier = Modifier
                            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                            .fillMaxSize(),
                        verticalArrangement = Arrangement.Top,
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        StaggeredVerticalGrid2(col = if (breakpoint <= Breakpoint.SM) 1 else 2, gap = 10) {
                            HesapBilgi()

                            PaketBilgi()

                            UserListView(adminUser, userList, onEdit = { edited ->
                                profileVM.editUser = edited
                                showEditUser = true
                            })

                        }

                    }

                }

            }

            if (breakpoint < Breakpoint.SM) {
                BottomNavigation(breakpoint)
            }

        }

        if (showEditUser) {
            DialogView {
                EditSharedUser(profileVM) {
                    showEditUser = false
                }
            }
        }
    }
}

