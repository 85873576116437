package com.kelimesoft.cowmaster.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.kelimesoft.cowmaster.components.BottomNavigation
import com.kelimesoft.cowmaster.components.LeftPane
import com.kelimesoft.cowmaster.components.PageBox
import com.kelimesoft.cowmaster.models.MilkTypeScreen
import com.kelimesoft.cowmaster.models.Screens
import com.kelimesoft.cowmaster.pages.milk.*
import com.kelimesoft.cowmaster.styles.MainPageStyle
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.PointerEvents
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px


@Page("/sut")
@Composable
fun MilkPage() {
    AppData.appViewModel.activeScreens = Screens.Milk
    Router()
}

@Composable
fun MilkPageData() {
    val breakpoint = rememberBreakpoint()

    var milkSelectedType = remember { mutableStateOf(MilkTypeScreen.Dairy) }

    val milkVM = AppData.milkVM
    LaunchedEffect(true){
        milkVM.getDairyMilk()
        milkVM.getCowMilk()
    }
    PageBox(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .pointerEvents(PointerEvents.Auto)
    ) {

        Column(
            modifier = MainPageStyle.toModifier()
                .overflow(Overflow.Hidden)
                .onClick {
                    closeDropDowns()
                }
            ,
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Row(
                modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden)
            ) {
                if (breakpoint >= Breakpoint.SM) {
                    LeftPane(breakpoint)
                }
                Column(modifier = Modifier.fillMaxSize()
                    .overflow { y(if (breakpoint <= Breakpoint.SM) Overflow.Auto else Overflow.Hidden) }
                    .overflow { x(Overflow.Hidden) }
                ) {
                    if (breakpoint <= Breakpoint.SM) {
                        MilkBar(milkSelectedType)
                        if (milkSelectedType.value == MilkTypeScreen.Dairy) {
                            DairyMilkView(milkVM)
                        } else {
                            CowMilkView(milkVM)
                        }
                    } else {
                        Row(
                            modifier = Modifier.fillMaxSize()
                                .padding(4.px)
                        ) {

                            DairyMilkView(milkVM)
                            CowMilkView(milkVM)
                        }


                    }

                    if (breakpoint < Breakpoint.SM) {
                        Spacer()
                        BottomNavigation(breakpoint)
                    }

                }
            }
        }
    }
}


