package com.kelimesoft.cowmaster.network

import com.kelimesoft.cowmaster.models.*
import kotlinx.serialization.Serializable

@Serializable
data class ApiResponse(
    val data: String?,
    val error: String? = null
)

@Serializable
data class BoolResponse(
    val data: Boolean?,
    val error: String? = null
)

@Serializable
data class LongResponse(
    val data: Long?,
    val error: String? = null
)

@Serializable
data class UserResponse(
    val data: CowUser?,
    val error: String? = null
)

@Serializable
data class SummaryResponse(
    val data: SummaryData?,
    val error: String? = null
)

@Serializable
data class CowListResponse(
    val data: CowListData?,
    val error: String? = null
)

@Serializable
data class ErrorListResponse(
    val data: ArrayList<ErrorCow>?,
    val error: String? = null
)

@Serializable
data class HerdListResponse(
    val data: ArrayList<HerdItem>?,
    val error: String? = null
)

@Serializable
data class HerdEditResponse(
    val data: String?,
    val error: String? = null
)

@Serializable
data class HerdList(
    var herdlist: ArrayList<HerdItem>?
)

@Serializable
data class SingleCowResponse(
    val data: ListCow?,
    val error: String? = null
)

@Serializable
data class CowListData(
    var cowlist: ArrayList<ListCow>?,
    var count: Int = 0
)

@Serializable
data class CowDetailsResponse(
    val data: CowDetails?,
    val error: String? = null
)

@Serializable
data class AddCowImgResponse(
    val data: CowImage?,
    val error: String? = null
)

@Serializable
data class ShowCowResponse(
    val data: ShorCowDetails?,
    val error: String? = null
)

@Serializable
data class CowNotifResponse(
    val data: List<CowNotif>?,
    val error: String? = null
)

@Serializable
data class CowMilkListResponse(
    val data: List<CowMilk>?,
    val error: String? = null
)

@Serializable
data class CowCalvesResponse(
    val data: List<CowCalf>?,
    val error: String? = null
)

@Serializable
data class NotifListResponse(
    val data: ArrayList<ListNotif>?,
    val error: String? = null
)

@Serializable
data class FinTypeList(
    val gelir: List<FinItem>?,
    val gider: List<FinItem>?,
)


@Serializable
data class DismissListResponse(
    val data: List<DisposedCow>?,
    val error: String? = null
)

@Serializable
data class TotalMilkResponse(
    val data: DairyMilkData?,
    val error: String? = null
)

@Serializable
data class CowMilkResponse(
    val data: CowMilkData?,
    val error: String? = null
)

@Serializable
data class EventListResponse(
    val data: ArrayList<ListEvent>?,
    val error: String? = null
)
@Serializable
data class EventCowListResponse(
    val data: ArrayList<EventCow>?,
    val error: String? = null
)


@Serializable
data class FinListResponse(
    val data: FinTypeList?,
    val error: String? = null
)


@Serializable
data class CaptchaData(
    val id: String,
    val text: String,
)