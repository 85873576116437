package com.kelimesoft.cowmaster.pages.milk

import androidx.compose.runtime.*
import com.kelimesoft.cowmaster.components.*
import com.kelimesoft.cowmaster.models.*
import com.kelimesoft.cowmaster.pages.closeDropDowns
import com.kelimesoft.cowmaster.styles.*
import com.kelimesoft.cowmaster.utils.Funcs
import com.kelimesoft.cowmaster.utils.Sprintf
import com.kelimesoft.cowmaster.utils.dateToDbStr
import com.kelimesoft.cowmaster.utils.tr
import com.kelimesoft.cowmaster.viewmodals.AppData
import com.kelimesoft.cowmaster.viewmodals.MilkVM
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputMode
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLDivElement
import kotlin.js.Date


enum class SagmalOrder {
    Kupe, Name
}

@Composable
fun AddManyCowMilk(milkVM: MilkVM, onClose: () -> Unit) {
    val scope = rememberCoroutineScope()
    var milkPeriod: MilkTime by remember { mutableStateOf(MilkTime.Morning) }
    var milkDate by remember { mutableStateOf(Date()) }
    var sagmalOrder by remember { mutableStateOf(SagmalOrder.Kupe) }
    //var eventCowList = remember { mutableStateListOf<EventCow>() }
    var errorText by remember { mutableStateOf("") }

    var sagmalList: List<SagmalInek> = when (sagmalOrder) {
        SagmalOrder.Kupe -> milkVM.sagmalCowList.sortedBy { it.kupe }
        SagmalOrder.Name -> milkVM.sagmalCowList.sortedBy { it.name }
    }

    LaunchedEffect(true) {
        milkVM.getSagmalCows()
    }

    var sagmalSayi: Int = sagmalList.fold(0){tpl, sag -> tpl + if (sag.milk > 0) 1 else 0  }
    var milkTop: Double = sagmalList.fold(0.0){tpl, sag -> tpl + sag.milk}




    fun saveManyMilk() {
        if (!Funcs.checkForm("form-manymilk")) {
            return
        }
        val manyMilkList = mutableListOf<ManyMilkItem>()
        var totalMilk = 0.0
        var sagmalSay = 0
        sagmalList.forEach { scow ->
            if (scow.milk > 0.0) {
                totalMilk += scow.milk
                sagmalSay += 1
                manyMilkList.add(ManyMilkItem(hid = scow.hid, milk = scow.milk))
            }
        }
        /*
        var mdate: String,
        var period: MilkTime,
        var milk: Double,
        var sagmal: Int,
        var list: List<ManyMilkItem>*/
        if (manyMilkList.isNotEmpty()) {
            val manyMilk = ManyCowMilk(milkDate.dateToDbStr(), milkPeriod, totalMilk, sagmalSay, manyMilkList)
            scope.launch {
                milkVM.addManyMilk(manyMilk)
                AppData.summaryViewModel.milkChangeReset()
                AppData.cowListVM.getCowList()
                onClose.invoke()
            }
        } else {
            errorText = "evt_bulk_notselect".tr()
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(480.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("addmilk_add".tr(), onClose = onClose)

        Column(
            Modifier
                .fillMaxWidth()
                .padding(topBottom = 6.px, leftRight = 8.px)
        ) {

            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.Start
            ) {
                Span(
                    attrs = Modifier
                        .fontSize(12.px)
                        .margin(top = 4.px)
                        .padding(1.px)
                        .color(AppColors.TextColor.copyf(alpha = 0.6f))
                        .toAttrs()
                ) {
                    Text("addmilk_time".tr())
                }


                Row(modifier = Modifier.fillMaxWidth()
                    .margin(bottom = 6.px)
                    , verticalAlignment = Alignment.CenterVertically
                ) {
                    Box(
                        Modifier
                            .fillMaxWidth(percent = 35.percent)
                            .margin(2.px)
                        ,
                        contentAlignment = Alignment.Center
                    ) {
                        DropDownMenu(
                            menuModifier = Modifier.fillMaxWidth(),
                            butonModifier = CowInputStyle.toModifier()
                                .fillMaxWidth()
                                .padding(6.px)
                                .margin(topBottom = 8.px)
                                .whiteSpace(WhiteSpace.NoWrap)
                                .onClick {
                                    it.stopPropagation()
                                    val menu = document.getElementById("add-drop") as HTMLDivElement
                                    if (menu.style.display == "block") {
                                        menu.style.display = "none"
                                    } else {
                                        closeDropDowns()
                                        menu.style.display = "block"
                                    }
                                },
                            id = "add-drop",
                            content = {
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Text(milkPeriod.text)
                                    Spacer()
                                    FaCaretDown(
                                        modifier = Modifier
                                            .color(AppColors.GrayDark),
                                        size = IconSize.LG
                                    )

                                }
                            }) {
                            MilkTime.entries.forEach { evt ->
                                Box(
                                    modifier = DropMenuItemStyle.toModifier()
                                        .padding(3.px)
                                        .color(AppColors.TextColor)
                                        .fontSize(14.px)
                                        .margin(bottom = 2.px)
                                        .onClick {
                                            if (milkPeriod != evt) {
                                                milkPeriod = evt
                                            }
                                        }
                                ) {

                                    Text(evt.text)

                                }
                            }
                        }
                        if (errorText.isNotEmpty()) {
                            ErrorText(errorText)
                        }

                    }

                    Input(
                        type = InputType.Date,
                        attrs = CowInputStyle.toModifier()
                            .fillMaxWidth()
                            .id("milk-date")
                            .padding(7.px)
                            .attrsModifier {
                                attr("required", "true")
                                attr("value", milkDate.dateToDbStr())
                                attr("max", Date().dateToDbStr())
                            }
                            .margin(2.px)
                            .toAttrs()
                            {
                                onInput {
                                    milkDate = Date(it.value)
                                    errorText = ""
                                }
                            }
                    )
                }

            }

            Form(
                attrs = Modifier
                    .id("form-manymilk")
                    .fillMaxWidth()
                    .toAttrs()
            ) {

                Column(modifier = Modifier
                    .fillMaxWidth()
                    .maxHeight(380.px)
                    .minHeight(250.px)
                    .backgroundColor(AppColors.Gray.opacity(0.06f))
                    .padding(2.px)
                    .boxShadow((-1).px, (-2).px, blurRadius = 1.px, color = Colors.Gray.opacity(0.2f))
                    .overflow { x(Overflow.Auto) }
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start
                    )
                    {
                        SpanText(
                            "evt_bulk_sagmal".tr(),
                            modifier = Modifier.margin(leftRight = 6.px)
                                .color(AppColors.TextColor)
                                .fontSize(11.px)
                        )
                        Spacer()

                        Row(
                            modifier = Modifier.padding(leftRight = 6.px)
                                .cursor(Cursor.Pointer)
                                .onClick {
                                    sagmalOrder = SagmalOrder.Kupe
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Start
                        )
                        {
                            SpanText("evt_bulk_kupe".tr(), modifier = Modifier.color(AppColors.TextColor))
                            FaCaretDown(
                                size = IconSize.XL, modifier = Modifier
                                    .padding(topBottom = 2.px, leftRight = 4.px)
                                    .color(if (sagmalOrder == SagmalOrder.Kupe) AppColors.NumBlue else AppColors.Gray)
                            )
                        }

                        Row(
                            modifier = Modifier.padding(leftRight = 6.px)
                                .cursor(Cursor.Pointer)
                                .onClick {
                                    sagmalOrder = SagmalOrder.Name
                                },
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Start
                        )
                        {
                            SpanText("evt_bulk_isim".tr(), modifier = Modifier.color(AppColors.TextColor))
                            FaCaretDown(
                                size = IconSize.XL, modifier = Modifier
                                    .padding(topBottom = 2.px, leftRight = 4.px)
                                    .color(if (sagmalOrder == SagmalOrder.Name) AppColors.NumBlue else AppColors.Gray)
                            )
                        }
                    }
                    VDivider(
                        modifier = Modifier.backgroundColor(AppColors.GrayDark)
                            .padding(topBottom = 0.2.px)
                            .fillMaxWidth()
                            .opacity(60.percent)
                    )
                    sagmalList.forEach { scow ->
                        SagmalCowRow(scow)
                    }
                }

                Row(modifier = Modifier.fillMaxWidth()
                    .padding(4.px)
                    .backgroundColor(AppColors.Gray.opacity(0.1f))
                    .borderRadius(4.px)
                    .margin(topBottom = 4.px)
                    ,
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                    //"detay_toast_sagmaltitle".tr().Sprintf((daysCalving ?: 0).toString())
                    SpanText("evt_bulk_sagmaltpl".tr().Sprintf(sagmalSayi.toString()),
                        modifier = Modifier
                            .color(AppColors.TextColor.opacity(0.8f))
                        .fontSize(14.px)
                    )
                    SpanText("evt_bulk_milktpl".tr().Sprintf(milkTop.toString()),
                            modifier = Modifier
                                .color(AppColors.TextColor.opacity(0.8f))
                                .margin(right = 8.px)
                            .fontSize(14.px)
                    )
                }



            }
            Row(
                modifier = Modifier.fillMaxWidth()
                    .margin(top = 20.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Button(
                    attrs = TextButtonStyle.toModifier()
                        .fontSize(16.px)
                        .color(AppColors.DarkRed)
                        .onClick {
                            it.preventDefault()
                            onClose()
                        }
                        .toAttrs()

                ) {
                    Text("shared_iptal".tr())
                }

                Spacer()
                Button(
                    attrs = MainButtonStyle.toModifier()
                        //.backgroundColor(AppColors.Red)
                        .onClick {
                            it.preventDefault()
                            saveManyMilk()
                        }
                        .toAttrs()

                ) {
                    Text("evt_bulk_add".tr())
                }
            }

        }


    }
    if (errorText.isNotEmpty()) {
        ToastView(errorText, pos = ToastPos.Center, color = Colors.Red) {
            errorText = ""
        }
    }

}

@Composable
fun SagmalCowRow(cow: SagmalInek) {
    var milkMik by remember { mutableStateOf(cow.milk.toString())}
    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    )
    {
        SpanText(text = cow.kupe, modifier = Modifier.fontSize(14.px))
        if (cow.name.isNotEmpty()){
            SpanText(text = cow.name, modifier = Modifier
                .margin(left = 4.px)
                .fontSize(12.px))
        }
        Spacer()
        ManyMilkMiktar(milkMik) { milkStr ->
            milkMik = milkStr.trim()
            if (milkMik.isEmpty()) {
                cow.milk = 0.0
                milkMik = "0"
            }else if (milkStr.endsWith(",") || milkStr.endsWith(".")){
                milkMik = milkMik.trim()
            } else {
                val normalizedValue = milkMik
                    .replace(',', '.')
                normalizedValue.toDoubleOrNull()?.let {
                    cow.milk = it
                    milkMik = cow.milk.toString()
                }
            }
        }

    }


}

@Composable
private fun ManyMilkMiktar(milk: String, onInput: (String) -> Unit) {
    Row(
        modifier = Modifier.padding(leftRight = 6.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Span(
            attrs = Modifier
                .whiteSpace(WhiteSpace.NoWrap)
                .margin(right = 4.px)
                .fontSize(12.px)
                .color(AppColors.TextColor)
                .toAttrs()
        ) {
            Text("lt".tr())
        }
        Input(
            type = InputType.Text,
            attrs = TopluInputStyle.toModifier()
                .textAlign(TextAlign.End)
                .width(64.px)
                .attrsModifier {
                    inputMode(InputMode.Decimal)
                    attr("required", "true")
                    attr("min", "0.0")
                    attr("max", "1000.0")
                    attr("step", "0.01")
                }
                .thenIf(milk.toDoubleOrNull() == null){
                    Modifier.color(Colors.Red)
                }
                .toAttrs()
                {
                    value(milk)
                    onInput { evt ->
                        onInput.invoke(evt.value)
                    }
                }
        )
    }
}

